import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import HomeScreen from '../screens/HomeScreen';
import PhotosScreen from '../screens/PhotosScreen';
import UploadScreen from '../screens/UploadScreen';
import QRcodeScreen from '../screens/QRcodeScreen';

const Stack = createStackNavigator();

export default function HomeStack() {
  return (
    <Stack.Navigator headerMode='false'>
      <Stack.Screen name='Home' component={HomeScreen} />
      <Stack.Screen name='Photos' component={PhotosScreen} />
      <Stack.Screen name='Upload' component={UploadScreen} />
      <Stack.Screen name='QRcode' component={QRcodeScreen} />
    </Stack.Navigator>
  );
}
