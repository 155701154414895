import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View, Button as RNButton, Image, Dimensions, TouchableHighlight, ScrollView, Linking} from 'react-native';

import { Button, InputField, ErrorMessage } from '../components';
import Firebase from '../config/firebase';
import { LinearGradient } from 'expo-linear-gradient';
import logo from '../assets/logo.png';
import { AntDesign } from '@expo/vector-icons';


const auth = Firebase.auth();

export default function LoginScreen({ navigation }) {


  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [rightIcon, setRightIcon] = useState('eye');
  const [loginError, setLoginError] = useState('');

  const handlePasswordVisibility = () => {
    if (rightIcon === 'eye') {
      setRightIcon('eye-off');
      setPasswordVisibility(!passwordVisibility);
    } else if (rightIcon === 'eye-off') {
      setRightIcon('eye');
      setPasswordVisibility(!passwordVisibility);
    }
  };

  const onLogin = async () => {
    try {
      if (email !== '' && password !== '') {
        await auth.signInWithEmailAndPassword(email, password);
      }
    } catch (error) {
      console.log(error);
      
      if(error == "Error: There is no user record corresponding to this identifier. The user may have been deleted."){
        setLoginError("Il n'y a pas d'utilisateur correspondant à cet identifiant. L'utilisateur a peut-être été supprimé.");
      } else if (error == "Error: The password is invalid or the user does not have a password."){
        setLoginError("Le mot de passe est invalide.");
      }  else if (error == "Error: The email address is badly formatted."){
        setLoginError("L'adresse email n'est pas au bon format.");
      }
       else {
        setLoginError(error.message);
       }
    }
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
      <LinearGradient
        // Background Linear Gradient
        colors={['#800087', '#800087', '#800087', '#be38cc', '#cb35ba', '#e350ad', '#ee5ea5', '#ff4d80', '#ff2f4f', '#ff2f4f', '#ff2f4f', '#ff2f4f']}
        style={{height:"150%"}}
      >
        <StatusBar style='light' />

        <View style={{  alignItems:"center" }}>


        <Image source={logo} style={{ width: 250, height: 250}} />



          {/* <Text style={styles.title}>Se connecter</Text> */}
          <InputField
            inputStyle={{
              fontSize: 16
            }}
            containerStyle={{
              backgroundColor: '#fff',
              marginBottom: 20,
              borderRadius: 20,
              width:300,
              marginTop:40
            }}
            leftIcon='email'
            placeholder='Entrer votre email'
            autoCapitalize='none'
            keyboardType='email-address'
            textContentType='emailAddress'
            autoFocus={true}
            value={email}
            onChangeText={text => setEmail(text)}
          />
          <InputField
            inputStyle={{
              fontSize: 16
            }}
            containerStyle={{
              backgroundColor: '#fff',
              marginBottom: 20,
              borderRadius: 20,
              width:300
            }}
            leftIcon='lock'
            placeholder='Entrer votre password'
            autoCapitalize='none'
            autoCorrect={false}
            secureTextEntry={passwordVisibility}
            textContentType='password'
            rightIcon={rightIcon}
            value={password}
            onChangeText={text => setPassword(text)}
            handlePasswordVisibility={handlePasswordVisibility}
          />
          {loginError ? <ErrorMessage error={loginError} visible={true} /> : null}
          {/* <Button
            borderRadius={20}
            onPress={onLogin}
            backgroundColor='#800087'
            title='Se connecter'
            tileColor='#fff'
            titleSize={20}
            containerStyle={{
              marginBottom: 24
            }}
          /> */}

          <TouchableHighlight
            style={{backgroundColor:"#800087", height:50, justifyContent:"center", alignItems:"center", borderRadius:40, marginBottom:20,  width:300}}
            onPress={onLogin}
            underlayColor='#fff'>
              <Text style={{color:"white", fontSize:16, fontWeight:"600"}} >Se connecter</Text>
          </TouchableHighlight>


          <TouchableHighlight
            style={{ height:50, justifyContent:"center", alignItems:"center", borderRadius:40, marginBottom:20}}
            onPress={() => navigation.navigate('Signup')}
            underlayColor='#fff'>
              <View>
              <Text style={{color:"white", fontSize:16, fontWeight:"600", textAlign:"center"}} >Vous n'avez pas encore de compte ? </Text>
              <Text style={{color:"white", fontSize:16, fontWeight:"600", textAlign:"center"}} >S'inscrire </Text>
              </View>
          </TouchableHighlight>

        
      
        </View>


        <AntDesign
          name='youtube'
          size={35}
          color='#fff'
          onPress={() => Linking.openURL("https://www.youtube.com/channel/UCYfguOt7iZEnxSgNUNffNQQ")}
          style={{ position: "absolute", right: 12, top: 15 }}
        />


        <AntDesign
          name='instagram'
          size={35}
          color='#fff'
          onPress={() => Linking.openURL("https://www.instagram.com/diapofamille")}
          style={{ position: "absolute", right: 12, top: 62 }}
        />


        <AntDesign
          name='mail'
          size={35}
          color='#fff'
          onPress={() => Linking.openURL("mailto:contactdiapofamille@gmail.com")}
          style={{ position: "absolute", right: 12, top: 110}}
        />
          



      </LinearGradient>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    height:"100%",
    backgroundColor: '#e93b81',
    margin: 0,
    padding: 0
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    color: '#fff',
    alignSelf: 'center',
    paddingBottom: 24
  }
});
