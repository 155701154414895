import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect, useRef } from 'react';
import {
  Text,
  View,
  StyleSheet,
  Image,
  Platform,
  useWindowDimensions,
  Button,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  FlatList,
  TouchableWithoutFeedback,
  Alert,
  Modal,
  Pressable
} from "react-native";
import Constants from "expo-constants";
import firebase from "../config/firebase";
import { WebView } from 'react-native-webview';

import ViewShot from "react-native-view-shot";
import * as Sharing from "expo-sharing";
import * as Clipboard from 'expo-clipboard';

import { AntDesign, Feather } from "@expo/vector-icons";
import uplodImageFromDevice from "../uploadImageFromDevice";
import uplodImageFromCamera from "../uploadImageFromCamera";
import getBlobFromUri from "../getBlobFromUri";
import manageFileUpload from "../manageFileUpload";
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';



export default function PhotosScreen({ route, navigation }) {
  /* Get the param */
  const { uuid } = route.params;

  const [modalVisible, setModalVisible] = useState(false);

  const viewShot = React.useRef();

  const [deleteSpinerVisible, setDeleteSpinerVisible] = useState(false);

  const [lastIndexDeleted, setLastIndexDeleted] = useState(0);

  const [currentUrlImage, setCurrentUrlImage] = useState("");
  const [currentIndexImage, setCurrentIndexImage] = useState(0)

  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([
    // { id: '1', url: "https://firebasestorage.googleapis.com/v0/b/diapofamille-b6fc7.appspot.com/o/images%2Fimg-1653321956695.jpg?alt=media&token=419d6e21-5479-474a-8636-d88a0279b697" },
    // { id: '2', url: "https://firebasestorage.googleapis.com/v0/b/diapofamille-b6fc7.appspot.com/o/images%2Fimg-1653320259070.jpg?alt=media&token=a0087b9e-d499-48c9-9bb7-af5b10ff38c4" },
    // { id: '3', url: "https://firebasestorage.googleapis.com/v0/b/diapofamille-b6fc7.appspot.com/o/images%2Fimg-1653320259070.jpg?alt=media&token=a0087b9e-d499-48c9-9bb7-af5b10ff38c4" }
  ]);

  const [imagesRef, setImagesRef] = useState([
  ]);

  const captureAndShareScreenshot = () => {
    viewShot.current.capture().then((uri) => {
      console.log("do something with ", uri);
      Sharing.shareAsync("file://" + uri);
    }),
      (error) => console.error("Oops, snapshot failed", error);
  };

  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;

  const getItem = (item, index) => {

    setCurrentUrlImage(item.url)
    setCurrentIndexImage(index)
    setModalVisible(true);

    // console.log("index:" + index);
    // console.log("image:" + item.url);
    // console.log("image:" + images[index].url);


    console.log("INDEX !!!!! " + index)

  }


  const deleteItem = (url) => {

    // console.log("delete item !!!" + currentUrlImage)
    // console.log("Current index image:" + currentIndexImage)
    //console.log("Image ref:" + imagesRef[index]);

    // deleteFromFirebase(imagesRef[currentIndexImage]);

    setDeleteSpinerVisible(true);


    console.log("Current index image:" + currentIndexImage)


    console.log("!!!!!!" + images[currentIndexImage].url);
    console.log("!!!!!!" + imagesRef[currentIndexImage]);
    console.log("!!!->'" + currentUrlImage);


    setCurrentUrlImage(currentUrlImage);
    deleteFromFirebase(url);



  }


  const deleteFromFirebase = (url) => {
    //1.
    var storage = firebase.storage();
    let pictureRef = storage.refFromURL(url);
    //2.
    pictureRef.delete()
      .then(() => {

        // Now delete the big one -> A revoir ....
        deleteFromFirebaseBig()
      

    


      })
      .catch((err) => {
        console.log(err);
        navigation.navigate('Home');
      });
  };


  const deleteFromFirebaseBig = () => {

    var urlBig = String(imagesRef[currentIndexImage]).replace('/photos', '');

    urlBig = String(urlBig).replace('_1920x1080', '');

     console.log("urlBig:" + urlBig);   

    var storage = firebase.storage();
    let pictureRef = storage.refFromURL(urlBig);
    //2.
    pictureRef.delete()
      .then(() => {
        console.log("currentIndexImage:" + currentIndexImage);

        var imagesTemp = [...images];
        imagesTemp.splice(currentIndexImage, 1);
        setImages(imagesTemp);

        setModalVisible(false);
        setDeleteSpinerVisible(false);


        saveNumberOfPhotos(imagesTemp.length);
       


        // setTimeout(function () {
        //   var imagesRefTemp = [...imagesRef];
        //   imagesRefTemp.splice(currentIndexImage, 1);
        //   setImages(imagesRefTemp);
        //   setModalVisible(false);

        //  }, 1000);

      })
      .catch((err) => {
        // console.log(err);
        // setModalVisible(false);
        // setDeleteSpinerVisible(false);
        // navigation.navigate('Home');
        var imagesTemp = [...images];
        imagesTemp.splice(currentIndexImage, 1);
        setImages(imagesTemp);

        setModalVisible(false);
        setDeleteSpinerVisible(false);


        saveNumberOfPhotos(imagesTemp.length);
       

      });
  };


  const saveNumberOfPhotos = async (newNumberOfImages) => {

    const userRef = firebase.database().ref(uuid)
    userRef.child("photos").set(newNumberOfImages);
  };


  useEffect(() => {

    const response = []
    const responseRef = []
    var i = 0;
    var storage = firebase.storage();
    var storageRef = storage.ref();

    storageRef.child(uuid + '/photos/').listAll().then(function (result) {
      console.log(uuid + '/photos/')
      if (result.items.length == 0) {
        // alert("Aucune photo n'est présente. Veuillez ajouter des photos dans le diaporama.")
        Alert.alert(
          "Aucunes photo n'est présente dans le diaporama",
          "Voulez-vous en ajouter une ?",
          [
            {
              text: "Oui", onPress: () => navigation.navigate('Upload', {
                uuid: uuid
              })
            },
            { text: "Non", onPress: () => navigation.navigate('Home') }
          ],
          { cancelable: false }
        );

      }
      result.items.forEach(function (imageRef) {
        responseRef.push(imageRef);
        imageRef.getDownloadURL().then(function (url) {

          response.push({ id: 0, url: url })
        })

      });

      console.log("result !!! : " + result.items.length)
      const userRef = firebase.database().ref(uuid)
      userRef.child("photos").set(result.items.length);
      
      

      if (JSON.stringify(result.items.length) != "0") {

        setTimeout(function () {
          // setCurrentIndex(2)
          // setIsStarting(false)
          setLoading(false)


        }, 1000);
      }

    }).catch(error => {
      console.log(error)
     
    })

    setImages(response);
    setImagesRef(responseRef);

   

    return
  }, []);


  if (loading) {
    return (
      <LinearGradient
        // Background Linear Gradient
        colors={['#800087', '#800087', '#800087', '#be38cc', '#cb35ba', '#e350ad', '#ee5ea5', '#ff4d80', '#ff2f4f', '#ff2f4f', '#ff2f4f', '#ff2f4f']}
        style={{ flex: 1 }}
      >
        <StatusBar style='light' />
        <ActivityIndicator size="small" color="white" style={{
          flex: 1,
          justifyContent: "center",
        }} />
      </LinearGradient>

    )
  }

  return (

    <View style={styles.container}>



      <LinearGradient
        // Background Linear Gradient
        colors={['#800087', '#800087', '#800087', '#be38cc', '#cb35ba', '#e350ad', '#ee5ea5', '#ff4d80', '#ff2f4f', '#ff2f4f', '#ff2f4f', '#ff2f4f']}
        style={{minHeight:windowHeight}}
      >
        <StatusBar style='light' />



        <TouchableOpacity onPress={() => navigation.navigate('Home')} style={{ position: "absolute", zIndex: "100", height: 50, width: "100%", alignItems: "left", flexDirection: "row", top: 30, left: 20 }}>
          <Ionicons
            name='arrow-back'
            size={24}
            color='#fff'
            onPress={() => navigation.navigate('Home')}
          />

          <TouchableOpacity
            style={{ height: 24, justifyContent: "center", alignItems: "center", borderRadius: 40, marginLeft: 5 }}
            onPress={() => navigation.navigate('Home')}
            underlayColor='#fff'>
            <Text style={{ color: "white", fontSize: 16, fontWeight: "600" }} >Retour</Text>
          </TouchableOpacity>
        </TouchableOpacity>

     

          {/* <Text>{images[0].url}</Text> */}
          {/* <Text>{imagesRef[0]}</Text> */}
          <FlatList
            contentContainerStyle={styles.list}
            data={images}
            keyExtractor={(images, index) => index.toString()}
            numColumns={2}
            renderItem={({ item, index }) => {
              return (

                <TouchableWithoutFeedback  onPress={() => getItem(item, index)}>
                  <View style={{
                    //flex:1,
                    marginLeft: windowWidth * 0.025,
                    marginTop: windowWidth * 0.025,
                    // width: windowWidth / 2.5,
                    // height: windowWidth / 2.5,
                    width: windowWidth * 0.45, height: windowWidth * 0.45,
                    backgroundColor: 'black'

                  }}>
                    {/* <Text>User ID: {item.id}</Text>  */}
                    <ActivityIndicator size="small" color="white" style={{
                      flex: 1,
                      justifyContent: "center",
                      position:"absolute",
                      top:"50%",
                      left:"50%"
                    }} />

                    <Image
                      source={{ uri: images[index].url }}
                      style={{  width: windowWidth * 0.45, height: windowWidth * 0.45, resizeMode: 'contain'}}
                    />
                    {/* <Text style={{ fontSize: 20, color: "white", position: "absolute" }}>User ID: {index}</Text> */}
                  </View>
                </TouchableWithoutFeedback>


              )
            }}
          />


        {modalVisible &&
          <View pointerEvents="none"  style={{ position:"absolute", width:"100%", height:"100%", backgroundColor: 'rgba(0,0,0,0.3)'}}/>
        }
       

        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert('Modal has been closed.');
            setModalVisible(!modalVisible);
          }}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              {/* <Text style={styles.modalText}>Hello World!</Text> */}
              <ViewShot
                ref={viewShot}
                options={{ format: "jpg", quality: 0.9 }}
              >
                {/* <Image
                source={{ uri: currentUrlImage }}
                style={{ width: windowWidth / 1.3, height: windowWidth / 1.3, resizeMode: "contain" }}
              /> */}
                <View style={{ width: windowWidth / 1.3, height: windowWidth / 1.3, backgroundColor: "black",  maxWidth:200, maxHeight:200  }}>
                  
                   <Image
                      source={{ uri: currentUrlImage }}
                      style={{  width: "100%", height: "100%", resizeMode: 'contain' }}
                    />

                </View>

              </ViewShot>


              <ActivityIndicator size="small" color="black" style={{
                marginTop: 50,
                width: 50,
                height: 0,
                justifyContent: "center",
                display: deleteSpinerVisible ? "block" : "none"
              }} />

              <Text style={{color:"grey", textAlign:"center", marginTop:10}}>Pour télécharger cette photo, faites un clic long dessus, puis enregistrer.</Text>

              <View style={{ flexDirection: "row", marginTop: 20, marginBottom: 20 }}>


                {/* <Pressable
                  style={[styles.button, styles.buttonClose, { backgroundColor: "#28a745", display: deleteSpinerVisible ? "none" : "block" }]}
                  onPress={captureAndShareScreenshot}>
                  <Text style={styles.textStyle}>Partager</Text>
                </Pressable> */}

                <Pressable
                  style={[styles.button, styles.buttonClose, { backgroundColor: "#dc3545", display: deleteSpinerVisible ? "none" : "block" }]}
                  onPress={() => deleteItem(currentUrlImage)}>
                  <Text style={styles.textStyle}>Supprimer</Text>
                </Pressable>
              </View>

              <Pressable
                style={[styles.button, styles.buttonClose, { marginTop: 20, marginBottom: 0, display: deleteSpinerVisible ? "none" : "block" }]}
                onPress={() => setModalVisible(!modalVisible)}>
                <Text style={styles.textStyle}>Fermer</Text>
              </Pressable>
            </View>
          </View>
        </Modal>




      </LinearGradient>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ecf0f1",
    overflow:"scroll",
    overflowX: "hidden",
    flex:1,
   
  },
  list: {
    justifyContent: 'center',
    // // flexDirection: 'row',
    marginTop: 80,
    flexWrap: 'wrap',
    paddingBottom: "50%"

  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    marginLeft: 20,
    marginRight: 20
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});
