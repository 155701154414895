import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View, Button as RNButton, Image, Dimensions, Modal, Pressable, TouchableHighlight, ScrollView, TouchableOpacity } from 'react-native';

import { Button, InputField, ErrorMessage } from '../components';
import Firebase from '../config/firebase';
import { LinearGradient } from 'expo-linear-gradient';
import logo from '../assets/logo.png';
import { Ionicons } from '@expo/vector-icons'; 
import { MaterialCommunityIcons } from '@expo/vector-icons'; 


const auth = Firebase.auth();

export default function SignupScreen({ navigation }) {

  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;

  const [modalVisible, setModalVisible] = useState(false);
  const [cgu, setCgu] = useState(false);

  const [email, setEmail] = useState('');
  const [calcul, setCalcul] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [rightIcon, setRightIcon] = useState('eye');
  const [signupError, setSignupError] = useState('');


  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const [number1, setNumber1] = useState(randomNumberInRange(1, 9));
  const [number2, setNumber2] = useState(randomNumberInRange(1, 9));

  const resultat = number1 * number2;




  const handlePasswordVisibility = () => {
    if (rightIcon === 'eye') {
      setRightIcon('eye-off');
      setPasswordVisibility(!passwordVisibility);
    } else if (rightIcon === 'eye-off') {
      setRightIcon('eye');
      setPasswordVisibility(!passwordVisibility);
    }
  };

  // const onHandleSignup = async () => {
  //   try {
  //     if (email !== '' && password !== '') {
  //       await auth.createUserWithEmailAndPassword(email, password);
  //     }
  //   } catch (error) {
  //    console.log(error)
  //     if(error == "Error: There is no user record corresponding to this identifier. The user may have been deleted."){
  //       setSignupError("Il n'y a pas d'utilisateur correspondant à cet identifiant. L'utilisateur a peut-être été supprimé.");
  //     } else if (error == "Error: The password is invalid or the user does not have a password."){
  //       setSignupError("Le mot de passe est invalide.");
  //     }  else if (error == "Error: The email address is badly formatted."){
  //       setSignupError("L'adresse email n'est pas au bon format.");
  //     } else if (error == "Error: Password should be at least 6 characters"){
  //       setSignupError("Le mot de pass doit comporter au minimum 4 caractères.");
  //     }
  //      else {
  //       setSignupError(error.message);
  //      }
  //   }
  // };

  // Email verification  adéveloper plus tard
  const onHandleSignup = async () => {

    if (calcul == resultat) {
      console.log("bravo !!!!")
    } else {
      alert("Le calcul n'est pas bon !")
      return
    }

    if (cgu == false) {
      alert("Vous devez accepter les CGU !")
    } 


    try {
      if (email !== '' && password !== '') {
        await auth.createUserWithEmailAndPassword(email, password).then((userCredential) => {
          // send verification mail.
          userCredential.user.sendEmailVerification();
          auth.signOut();

          navigation.navigate('Login')
        })
      }
    } catch (error) {
      console.log(error)
      if (error == "Error: There is no user record corresponding to this identifier. The user may have been deleted.") {
        setSignupError("Il n'y a pas d'utilisateur correspondant à cet identifiant. L'utilisateur a peut-être été supprimé.");
      } else if (error == "Error: The password is invalid or the user does not have a password.") {
        setSignupError("Le mot de passe est invalide.");
      } else if (error == "Error: The email address is badly formatted.") {
        setSignupError("L'adresse email n'est pas au bon format.");
      } else if (error == "Error: Password should be at least 6 characters") {
        setSignupError("Le mot de pass doit comporter au minimum 4 caractères.");
      }
      else {
        setSignupError(error.message);
      }
    }
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
      <LinearGradient
        // Background Linear Gradient
        colors={['#800087', '#800087', '#800087', '#be38cc', '#cb35ba', '#e350ad', '#ee5ea5', '#ff4d80', '#ff2f4f', '#ff2f4f', '#ff2f4f', '#ff2f4f']}
        style={{ height: "130%" }}
      >
        <StatusBar style='light' />

        <View style={{ alignItems: "center" }}>


          <Image source={logo} style={{ width: 250, height: 250 }} />

          <InputField
            inputStyle={{
              fontSize: 16
            }}
            containerStyle={{
              backgroundColor: '#fff',
              marginBottom: 20,
              borderRadius: 20,
              marginTop: 40,
              width: 300
            }}
            leftIcon='email'
            placeholder='Entrer votre email'
            autoCapitalize='none'
            keyboardType='email-address'
            textContentType='emailAddress'
            autoFocus={true}
            value={email}
            onChangeText={text => setEmail(text)}
          />
          <InputField
            inputStyle={{
              fontSize: 16
            }}
            containerStyle={{
              backgroundColor: '#fff',
              marginBottom: 10,
              borderRadius: 20,
              width: 300,
            }}
            leftIcon='lock'
            placeholder='Entrer votre password'
            autoCapitalize='none'
            autoCorrect={false}
            secureTextEntry={passwordVisibility}
            textContentType='password'
            rightIcon={rightIcon}
            value={password}
            onChangeText={text => setPassword(text)}
            handlePasswordVisibility={handlePasswordVisibility}
          />
          {signupError ? <ErrorMessage error={signupError} visible={true} /> : null}




          <Text style={{
            fontSize: 16,
            color: "white",
            fontWeight: "600",
            marginBottom: 5


          }}>{number1} x {number2} = ?</Text>
          <InputField
            inputStyle={{
              fontSize: 16
            }}
            containerStyle={{
              backgroundColor: '#fff',
              marginBottom: 20,
              borderRadius: 20,
              width: 300
            }}
            leftIcon='lock-question'
            placeholder='Trouvez le résultat'
            autoCapitalize='none'
            keyboardType='email-address'
            textContentType='emailAddress'
            autoFocus={true}
            value={calcul}
            onChangeText={text => setCalcul(text)}
          />


          <TouchableOpacity onPress={() => console.log("modal")} style={{ height: 50, width:300, alignItems: "left", flexDirection: "row", top: 0,  marginBottom: 0 }}>
            <MaterialCommunityIcons
              onPress={() => setCgu(!cgu)}
              name= {cgu ? "checkbox-outline" : "checkbox-blank-outline"}
              size={24}
              color='#fff'

            />

            <TouchableOpacity  onPress={() => setModalVisible(true)}
              style={{ height: 24, justifyContent: "center", alignItems: "center", borderRadius: 40, marginLeft: 5 }}
              underlayColor='#fff'>
              <Text style={{ color: "white", fontSize: 16, fontWeight: "600", textDecorationLine: 'underline' }} >Conditions générales d'utilisation</Text>
            </TouchableOpacity>
          </TouchableOpacity>



          <TouchableOpacity
            style={{ backgroundColor: "#800087", height: 50, justifyContent: "center", alignItems: "center", borderRadius: 40, marginBottom: 20, width: 300 }}
            onPress={onHandleSignup}
            underlayColor='#fff'>
            <Text style={{ color: "white", fontSize: 16, fontWeight: "600" }} >S'inscrire</Text>
          </TouchableOpacity>



          <TouchableHighlight
            style={{ height: 50, justifyContent: "center", alignItems: "center", borderRadius: 40, marginBottom: 20 }}
            onPress={() => navigation.navigate('Login')}
            underlayColor='#fff'>
            <View>
              <Text style={{ color: "white", fontSize: 16, fontWeight: "600", textAlign: "center" }} >Vous avez déjà compte ? </Text>
              <Text style={{ color: "white", fontSize: 16, fontWeight: "600", textAlign: "center" }} >Se connecter </Text>
            </View>
          </TouchableHighlight>

          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              Alert.alert('Modal has been closed.');
              setModalVisible(!modalVisible);
            }}>
            <View style={styles.modalView}>
              <Text style={{ fontSize: 20 }}>Conditions Générales d'Utilisation</Text>
              <Text style={[styles.modalText, { marginTop: 10 }]}>Le site DIAPOFAMILE.COM est un espace de stockage de photo. </Text>

              <Text style={styles.modalText}>Les diaporamas sont protégés par un code dans l'url. Toutes les personnes ayant le lien du diaporama ont accès aux photos sans authentification. </Text>
              <Text style={styles.modalText}>Cet espace de stockage est pensé pour des évènements éphémères, et tout le contenu des photos est effacé automatiquement lorsque l'évènement est terminé. Les photos ne sont plus visibles et seront irrécupérable.</Text>
              <Text style={styles.modalText}>Si l'utilisateur attends la fin de validité du diaporama ou si il décide d'effacer toutes les photos, un nouveau diaporama avec un nouveau code et nouvelle url seront créé. </Text>
              <Text style={[styles.modalText]}>Par defaut un diaporama créé a une durée de validité de 7 jours et une limite de 25 photos maximum.</Text>
              <Text style={[styles.modalText]}>Pour augmenter le délais de stockage et passer la limite à 100 photos, il faut passer en mode PREMIUM.</Text>
              <Text style={[styles.modalText]}>Pour toute question vous nous contacter par email : contactdiapofamille@gmail.com</Text>
              <Text style={[styles.modalText]}>En créant un compte et en utilisant cette application, vous acceptez les règles suvantes.</Text>

              <Pressable
                style={[styles.button, styles.buttonClose, { backgroundColor: "#28a745" }]}
                onPress={() => { setModalVisible(false) , setCgu(true) }}>
                <Text style={styles.textStyle}>J'ai lu les conditions</Text>
              </Pressable>
            </View>
          </Modal>


        </View>
      </LinearGradient>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#e93b81'
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    color: '#fff',
    alignSelf: 'center',
    paddingBottom: 24
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    fontSize:12,
    marginBottom: 5,
    textAlign: 'center',
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    marginLeft: 20,
    marginRight: 20
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    marginLeft: 10,
    marginRight: 10
  },

});
