import { StatusBar } from 'expo-status-bar';
import React, { useContext, useState } from 'react';
import { StyleSheet, Text, View, Button, Dimensions, TouchableOpacity, Image, TouchableHighlight , Share, Switch} from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import * as Linking from 'expo-linking';

import { IconButton } from '../components';
import { Ionicons } from '@expo/vector-icons'; 

import Firebase from '../config/firebase';
import * as WebBrowser from 'expo-web-browser';

import ViewShot from "react-native-view-shot";
import * as Sharing from "expo-sharing";
import * as Clipboard from 'expo-clipboard';

import { LinearGradient } from 'expo-linear-gradient';


export default function QRcodeScreen({ route, navigation }) {

  const [isEnabled, setIsEnabled] = useState(true);
  const toggleSwitch = () => setIsEnabled(previousState => !previousState);

  const [result, setResult] = useState(null);
  const [svg, setSvg] = useState(null);

  const viewShot = React.useRef();

  const [copiedText, setCopiedText] = React.useState("");

  const copyToClipboard = async () => {
    await Clipboard.setStringAsync(diapo_url);
    setCopiedText("dfefef")
  };

  const shareLink = async () => {
    Share.share({
      message: diapo_url,
      title: "Diapo Famille",
    });
  };

  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;


  const captureAndShareScreenshot = () => {
    console.log("click on qrcode ");


    


    // viewShot.current.capture().then((uri) => {
    //   console.log("do something with ", uri);
    //   Sharing.shareAsync("file://" + uri);
    // }),
    //   (error) => console.error("Oops, snapshot failed", error);
  };


  /* Get the param */
  const { uuid, code } = route.params;

  let diapo_url = "https://diapofamille-b6fc7.web.app?diapo=" + uuid + "&code=" + code + "&upload=" + isEnabled


  const _handlePressButtonAsync = async () => {
    // let result = await WebBrowser.openBrowserAsync(diapo_url);
    // setResult(result);
    Linking.openURL('https://expo.dev');
  };


  return (
    <View style={styles.container}>


      <LinearGradient
        // Background Linear Gradient
        colors={['#800087', '#800087', '#800087', '#be38cc', '#cb35ba', '#e350ad', '#ee5ea5', '#ff4d80', '#ff2f4f', '#ff2f4f', '#ff2f4f', '#ff2f4f']}
        style={{height:"100%"}}
      >
        <StatusBar style='light' />

        <View style={{ marginTop: 0, height: "100%", margin: 20 , alignItems:"center" }}>



          <TouchableOpacity onPress={() => navigation.navigate('Home')} style={{ height: windowHeight/18, width: "100%", alignItems: "left", flexDirection: "row",  top: 20, left:0, marginBottom:20 }}>
            <Ionicons
              name='arrow-back'
              size={24}
              color='#fff'
              
            />

            <TouchableOpacity onPress={() => navigation.navigate('Home')}
              style={{ height: 24, justifyContent: "center", alignItems: "center", borderRadius: 40, marginLeft: 5 }}
              underlayColor='#fff'>
              <Text style={{ color: "white", fontSize: 16, fontWeight: "600" }} >Retour</Text>
            </TouchableOpacity>
          </TouchableOpacity>




          {/* <Text>QR Code : {uuid}</Text> */}





          {copiedText == "" &&




            <TouchableHighlight
              style={{ backgroundColor: "#ff2f4f", width: "100%", maxWidth:300, height: windowHeight/18, justifyContent: "center", alignItems: "center", borderRadius: 40, marginBottom: 10 }}
              onPress={copyToClipboard}
              underlayColor='#fff'>
              <Text style={{ color: "white", fontSize: 16, fontWeight: "600" }} >Copier le lien du diaporama</Text>
            </TouchableHighlight>



          }

          {copiedText != "" &&

            <TouchableHighlight
              style={{ backgroundColor: "#BCED91", width: "100%", maxWidth:300,  height:  windowHeight/18, justifyContent: "center", alignItems: "center", borderRadius: 40, marginBottom: 10 }}
              onPress={copyToClipboard}
              underlayColor='#fff'>
              <Text style={{ color: "black", fontSize: 16, fontWeight: "600" }} >Lien copié</Text>
            </TouchableHighlight>

          }

          {/* <TouchableHighlight
            style={{ backgroundColor: "#ff2f4f", height: 50, justifyContent: "center", alignItems: "center", borderRadius: 40, marginBottom: 10, marginTop: 10 }}
            onPress={shareLink}
            underlayColor='#fff'>
            <Text style={{ color: "white", fontSize: 16, fontWeight: "600" }} >Partager le lien du diaporama</Text>
          </TouchableHighlight> */}

          <TouchableHighlight
            style={{ backgroundColor: "#ff2f4f", width: "100%", maxWidth:300, height:  windowHeight/18, justifyContent: "center", alignItems: "center", borderRadius: 40, marginBottom: 10, marginTop: 10 }}
            // onPress={_handlePressButtonAsync}
            underlayColor='#fff'>
           
            <a style={{ color: "white", fontSize: 16, fontWeight: "600", textDecoration:"none" }} href={diapo_url} target="_blank">Ouvrir le Diaporama</a>
          </TouchableHighlight>


          {/* <Text style={{ fontSize: 16, marginLeft: 20, marginRight: 20, marginTop: 10, textAlign: "center", color:"white" }}>Vous pouvez partager ce diaporama privé avec vos amis.</Text>
          <Text style={{ fontSize: 16, marginLeft: 20, marginRight: 20, textAlign: "center", marginTop: 10, color:"white" }}>Il pourront envoyer leurs photos directement, sans avoir à télecharger une application, ou s'identifier. </Text> */}
     <Text style={{ fontSize: 16, marginLeft: 20, marginRight: 20, marginTop: 10, textAlign: "center", color:"white" }}>{isEnabled ? "Les visieurs pourront envoyer des photos":"Les visieurs ne pouront pas envoyer des photos"} </Text>
     <Switch
        trackColor={{ false: "#767577", true: "#00d00b" }}
        thumbColor={isEnabled ? "#f5dd4b" : "#f4f3f4"}
        ios_backgroundColor="#3e3e3e"
        onValueChange={toggleSwitch}
        value={isEnabled}
        style={{marginTop:20}}
      />

          

          {/* <TouchableHighlight
            style={{ backgroundColor: "#b23fde", height: 50, justifyContent: "center", alignItems: "center", borderRadius: 40, marginBottom: 20, marginTop: 20 }}
            onPress={captureAndShareScreenshot}
            underlayColor='#fff'>
            <Text style={{ color: "white", fontSize: 16, fontWeight: "600" }} >Partager le QR Code</Text>
          </TouchableHighlight> */}

        

        </View>

        <View style={{  position:"absolute", left:0, bottom:0, justifyContent:"center", alignItems:"center", width:"100%", marginBottom: 50 }}>
           
                <View style={styles.viewShot}>

                  <QRCode
                    // size={windowWidth < windowHeight ? windowWidth / 1.5: 400 }
                    size={300}
                    backgroundColor='white'
                    value={diapo_url}
                    getRef={c => (setSvg(c))}

                  />
                </View>
         
          </View>
      </LinearGradient>


    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white'
  },
  button: {
    alignItems: "center",
    backgroundColor: "#DDDDDD",
    padding: 10,
    margin: 10
  },
});
