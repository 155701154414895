import { StatusBar } from 'expo-status-bar';
import * as React from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  Platform,
  useWindowDimensions,
  Button,
  TouchableOpacity,
  Dimensions
} from "react-native";
import Constants from "expo-constants";
import firebase from "../config/firebase";
import { AntDesign, Feather } from "@expo/vector-icons";
import uplodImageFromDevice from "../uploadImageFromDevice";
import uplodImageFromCamera from "../uploadImageFromCamera";
import getBlobFromUri from "../getBlobFromUri";
import manageFileUpload from "../manageFileUpload";
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';



export default function UploadScreen({ route, navigation }) {
  /* Get the param */
  const { uuid, imagesNumber, imagesLimit } = route.params;

  const [numberOfImagesSuccessUpload, setNumberOfImagesSuccessUpload] = React.useState(0);

  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;

  const [imgURI, setImageURI] = React.useState(null);

  const [isUploading, setIsUploading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [remoteURL, setRemoteURL] = React.useState("");

  const [error, setError] = React.useState(null);
  const { width } = useWindowDimensions();

  const handleLocalImageUpload = async () => {
    setRemoteURL("");
    const fileURI = await uplodImageFromDevice();

    if (fileURI) {
      setImageURI(fileURI);
    }
  };

  const handleCameraImageUpload = async () => {
    setRemoteURL("");
    const fileURI = await uplodImageFromCamera();

    if (fileURI) {
      setImageURI(fileURI);
    }
  };

  const onStart = () => {
    setIsUploading(true);
  };

  const onProgress = (progress) => {
    setProgress(progress);
  };
  const onComplete = (fileUrl) => {
    setRemoteURL(fileUrl);
    setIsUploading(false);
    setImageURI(null);

    const userRef = firebase.database().ref(uuid)
    userRef.child("photos").set(imagesNumber + 1);
    setNumberOfImagesSuccessUpload(Number(numberOfImagesSuccessUpload) + 1);

    if(numberOfImagesSuccessUpload + imagesNumber + 1 >= imagesLimit){

     
      setTimeout(function () {
        navigation.navigate('Home');
      }, 1000);
    }
    

    console.log("imagesNumber !!! okkokokoko : "+ imagesNumber)
  };

  const onFail = (error) => {
    setError(error);
    setIsUploading(false);
  };
  const handleCloudImageUpload = async () => {
    setRemoteURL("");
    if (!imgURI) return;

    let fileToUpload = null;

    const blob = await getBlobFromUri(imgURI);

    await manageFileUpload(blob, { onStart, onProgress, onComplete, onFail }, uuid);

  };

  return (
    <View style={styles.container}>


      <LinearGradient
        // Background Linear Gradient
        colors={['#800087', '#800087', '#800087', '#be38cc', '#cb35ba', '#e350ad', '#ee5ea5', '#ff4d80', '#ff2f4f', '#ff2f4f', '#ff2f4f', '#ff2f4f']}
        style={{height:"100%"}}
      >
        <StatusBar style='light' />
      

        <TouchableOpacity onPress={() => navigation.navigate('Home')} style={{ position: "absolute", zIndex: "100", height: 50, width: "100%", alignItems: "left", flexDirection: "row", top: 20, left: 20 }}>
          <Ionicons
            name='arrow-back'
            size={24}
            color='#fff'
            onPress={() => navigation.navigate('Home')}
          />

          <TouchableOpacity
            style={{ height: 24, justifyContent: "center", alignItems: "center", borderRadius: 40, marginLeft: 5 }}
            onPress={() => navigation.navigate('Home')}
            underlayColor='#fff'>
            <Text style={{ color: "white", fontSize: 16, fontWeight: "600" }} >Retour</Text>
          </TouchableOpacity>
        </TouchableOpacity>

        <View style={{ marginTop: 50, height: "100%", margin: 20 }}>

          {/* <Button
      color={"white"}
        title="Retour Home"
        onPress={() => navigation.navigate('Home')}
      /> */}



          {/* <Text>itemId: {JSON.stringify(uuid)}</Text>
      <Text style={styles.heading}>Attach and upload image</Text> */}
          {Boolean(imgURI) && (
            <View>
              <Image
                source={{ uri: imgURI }}
                resizeMode="contain"
                style={{ width:"100%", height:200, textAlign:"center", marginTop: 20}}
              />
            </View>
          )}

          {!isUploading && (
            <View style={{
              position: "absolute", width: "100%", marginTop: Boolean(imgURI) ?  windowHeight / 2 : windowHeight / 2.5, flexDirection: "row", justifyContent: "center"
            }}>
              <TouchableOpacity onPress={handleLocalImageUpload} style={{ flexDirection: "column", alignItems: "center", marginLeft:20, marginRight:20 }}>
                {/* <Text style={{  color: "white", fontSize: 16, fontWeight: "600" }}>{imgURI ? "Sélectionner" : "Sélectionner une photo"}</Text> */}
                <AntDesign
                  style={{ margin: 20 }}
                  name="addfile"
                  size={36}
                  color={imgURI ? "white" : "white"}
                />
              </TouchableOpacity>

              <TouchableOpacity onPress={handleCameraImageUpload} style={{ flexDirection: "column", alignItems: "center", marginLeft:20, marginRight:20 }}>
                {/* <Text style={{  color: "white", fontSize: 16, fontWeight: "600" }}>{imgURI ? "Sélectionner" : "Sélectionner une photo"}</Text> */}
                <AntDesign
                  style={{ margin: 20 }}
                  name="camerao"
                  size={40}
                  color={imgURI ? "white" : "white"}
                />
              </TouchableOpacity>


              {Boolean(imgURI) && (
                <TouchableOpacity onPress={handleCloudImageUpload} style={{ flexDirection: "column", alignItems: "center", marginLeft:20, marginRight:20 }}>
                  <Text style={{position:"absolute", display: imgURI ? "block" : "none", color: "white", fontSize: 16, fontWeight: "600" }}>Envoyer</Text>
                  <Feather
                    style={{ margin: 20 }}
                    name="upload-cloud"
                    size={36}
                    color="white"
                    onPress={handleCloudImageUpload}
                  />
                </TouchableOpacity>
              )}
            </View>
          )}

          {isUploading && (
            <View style={styles.uploadProgressContainer}>
              <Text style={{ fontSize: 16, color: "white" }}> Télechargement {progress} of 100% </Text>
            </View>
          )}

          {Boolean(remoteURL) && (
            <View style={{ position: 'absolute', marginTop: windowHeight / 4, width: "100%" }}>
              <Text style={{ fontSize: 16, fontWeight: "600", color: "white", width: "100%", textAlign: "center" }}>
                La photo a bien étée envoyée !
                {/* <Text style={{ color: "blue" }}> {remoteURL} </Text> */}
              </Text>
              <Text style={{ fontSize: 16, fontWeight: "600", color: "white", width: "100%", textAlign: "center" }}>
                Elle apparaitra sur le diaporama dans quelques minutes.
                {/* <Text style={{ color: "blue" }}> {remoteURL} </Text> */}
              </Text>
            </View>
          )}

        </View>
      </LinearGradient>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#ecf0f1",
  },

  row: {
    // flex: 1,
    // marginBottom: windowHeight / 2,
    // alignItems: "center",
    // justifyContent: "space-around",
    // flexDirection: "row",
  },
  uploadProgressContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  heading: {

    fontSize: 18,
    fontWeight: "bold",
  },
});
