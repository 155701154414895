import * as ImagePicker from "expo-image-picker";

const hasMediaLibraryPermissionGranted = async () => {
  let granted =  false;

  const permission = await ImagePicker.requestMediaLibraryPermissionsAsync();
  const permission2 = await ImagePicker.requestCameraPermissionsAsync();

  if (!permission.canAskAgain || permission.status === "denied") {
    granted = false;

  }
  if (!permission2.canAskAgain || permission2.status === "denied") {
    granted = false;

  }


  if (permission2.granted && permission.granted) {
    granted = true;
  }

  return granted;
};

export default hasMediaLibraryPermissionGranted