import * as ImagePicker from "expo-image-picker";
import hasMediaLibraryPermissionGranted from "./hasMediaLibraryPermissionGranted"


const uploadImageFromCamera = async () => {
  let imgURI = null;
  const storagePermissionGranted = await hasMediaLibraryPermissionGranted();

  // Discard execution when  media library permission denied
  //if (!storagePermissionGranted) return imgURI;  // Retirer car demande de permission innutile

  let result = await ImagePicker.launchCameraAsync({
    allowsEditing: false,
    aspect: [4, 3],
    base64: true,
  });

  if (!result.cancelled) {
    imgURI = result.uri;
  }

  return imgURI;
};

export default uploadImageFromCamera;