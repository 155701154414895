import { StatusBar } from 'expo-status-bar';
import React, { useContext, useRef, useEffect, useState } from 'react';
import { StyleSheet, Text, Linking, View, Button, ScrollView, Dimensions, TouchableHighlight, Modal, Pressable, ActivityIndicator, ImageStore } from 'react-native';

import { IconButton } from '../components';
import { Ionicons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import Firebase from '../config/firebase';
import * as moment from 'moment';
import { PayPalButton } from "react-paypal-button-v2";
import { v4 as uuidv4 } from 'uuid';

import { AuthenticatedUserContext } from '../navigation/AuthenticatedUserProvider';

import { LinearGradient } from 'expo-linear-gradient';


const auth = Firebase.auth();
const database = Firebase.database();

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;


export default function HomeScreen({ navigation }) {


  let now = moment().format('YYYY-MM-DD');
  const [days, setDays] = useState(0)

  const [date, setDate] = useState("");

  const [imagesNumber, setImagesNumber] = useState([]);
  const [imagesLimit, setImagesLimit] = useState([]);
  const [code, setCode] = useState("");

  const [transactionComplete, setTransactionComplete] = useState(false);



  const [modalVisible, setModalVisible] = useState(false);

  const [modalVisibleLimitAddImages, setModalVisibleLimitAddImages] = useState(false);


  const [deleteSpinerVisible, setDeleteSpinerVisible] = useState(false);




  const { user } = useContext(AuthenticatedUserContext);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.log(error);
    }
  };


  const paypallAction = async () => {
    console.log(("paypall action"));
    //saveLimitOfPhotos();
  };

  const saveLimitOfPhotos = async () => {
    const userRef = Firebase.database().ref(user.uid)
    userRef.child("limit").set(100);
    userRef.child("days").set(30);
    userRef.child("date").set(moment().add(1, 'month').format("YYYY-MM-DD"));

    setModalVisibleLimitAddImages(!modalVisibleLimitAddImages)
  };


  const resetNumberOfPhoto = async () => {
    const userRef = Firebase.database().ref(user.uid)
    userRef.child("photos").set(0);
  };


  const resetLimitOfPhotos = async () => {
    const userRef = Firebase.database().ref(user.uid)

    let newCode = uuidv4();
    userRef.child("code").set(newCode);

    if (imagesLimit <= 25) { userRef.child("limit").set(25) };

    if (days <= 7) {
      userRef.child("days").set(7);
      userRef.child("date").set(moment().add(7, 'days').format("YYYY-MM-DD"));
    };


  };



  useEffect(() => {

    const userRef = Firebase.database().ref(user.uid)
    userRef.on('value', (snapshot) => {
      let user = snapshot.val();

      // setImagesNumber(user.photos)
      // setImagesLimit(user.limit)

      if (user == null) {

        let newCode = uuidv4();

        userRef.child("code").set(newCode);
        userRef.child("limit").set(25);
        userRef.child("photos").set(0);
        userRef.child("days").set(7);
        userRef.child("date").set(moment().add(7, 'days').format("YYYY-MM-DD")); // Firebase.database.ServerValue.TIMESTAMP
        setDate(moment().add(7, 'days').format("YYYY-MM-DD"))
        setImagesNumber(0)
        setCode(newCode)
        setImagesLimit(25)
        setDays(Math.round(moment.duration(moment(moment().add(7, 'days').format("YYYY-MM-DD"), "YYYY-MM-DD").diff(now)).asDays()))
      } else {
        setImagesNumber(user.photos)
        setImagesLimit(user.limit)
        setDate(user.date)
        setDays(Math.round(moment.duration(moment(user.date, "YYYY-MM-DD").diff(now)).asDays()))
        setCode(user.code)


        if (Math.round(moment.duration(moment(user.date, "YYYY-MM-DD").diff(now)).asDays()) <= 0) {
          console.log("Effacement de toutes les photos !!!");
          deleteFolderPhotos();
          userRef.child("days").set(7);
          userRef.child("photos").set(0);
          userRef.child("limit").set(25);
          userRef.child("date").set(moment().add(7, 'days').format("YYYY-MM-DD"));
        }
      }


    })
    return
  }, []);




  // useEffect(() => {

  //   const response = []
  //   const responseRef = []
  //   var i = 0;
  //   var storage = Firebase.storage();
  //   var storageRef = storage.ref();

  //   storageRef.child(user.uid + '/photos/').listAll().then(function (result) {
  //     console.log("result !!! : " + result.items.length)
  //     setImagesNumber(result.items.length);

  //   }).catch(error => {
  //     console.log(error);
  //     setImagesNumber(0);
  //   })


  //   return
  // }, []);





  const deleteFolder = () => {
    setDeleteSpinerVisible(true);

    let ref = Firebase.storage().ref(user.uid);
    ref.listAll().then(dir => {
      dir.items.forEach(fileRef => {
        var dirRef = Firebase.storage().ref(fileRef.fullPath);
        dirRef.getDownloadURL().then(function (url) {
          var imgRef = Firebase.storage().refFromURL(url);
          imgRef.delete().then(function () {
            // File deleted successfully 

          }).catch(function (error) {
            // There has been an error      
          });
        });
      });
      deleteFolderPhotos();
    }).catch(error => {
      console.log(error);
    });
  };


  const deleteFolderPhotos = () => {
    //1.

    let ref = Firebase.storage().ref(user.uid + "/photos");
    ref.listAll().then(dir => {
      dir.items.forEach(fileRef => {
        var dirRef = Firebase.storage().ref(fileRef.fullPath);
        dirRef.getDownloadURL().then(function (url) {
          var imgRef = Firebase.storage().refFromURL(url);
          imgRef.delete().then(function () {
            // File deleted successfully 

            setImagesNumber(0);
            resetLimitOfPhotos();
            resetNumberOfPhoto(0);

          }).catch(function (error) {
            // There has been an error      
          });
        });
      });

      setDeleteSpinerVisible(false);
      setModalVisible(false);
    }).catch(error => {
      console.log(error);
    });
  };


  // let jsCode = `document.querySelector('.info-download').style.display = 'none'; `;

  return (
    <View style={[styles.container]}>
      <LinearGradient
        // Background Linear Gradient
        colors={['#800087', '#800087', '#800087', '#be38cc', '#cb35ba', '#e350ad', '#ee5ea5', '#ff4d80', '#ff2f4f', '#ff2f4f', '#ff2f4f', '#ff2f4f']}
        style={{ height: "100%" }}
      >
        <StatusBar style='light' />

        <View style={{ alignItems: "center", minHeight: "100%" }}>
          <View style={{ marginTop: 20, height: "100%", margin: 20, maxWidth: "350px" }}>


        <View style={{
        display: windowWidth >= 399 ? "none" : "block",
        height: windowWidth >= 399 ? 0 : 40,
        width:"100%",
        display:"flex",
        flexWrap: "wrap",
        alignContent: "space-between"
        }}>
          <AntDesign
          name='youtube'
          size={35}
          color='#fff'
          onPress={() => Linking.openURL("https://www.youtube.com/channel/UCYfguOt7iZEnxSgNUNffNQQ")}
         style={{ display: windowWidth >= 399 ? "none" : "block",}}
        />


        <AntDesign
          name='instagram'
          size={35}
          color='#fff'
          onPress={() => Linking.openURL("https://www.instagram.com/diapofamille")}
          style={{ display: windowWidth >= 399 ? "none" : "block",}}
        />

        <AntDesign
          name='mail'
          size={35}
          color='#fff'
          onPress={() => Linking.openURL("mailto:contactdiapofamille@gmail.com")}
          style={{ display: windowWidth >= 399 ? "none" : "block",}}
        />
          </View>


            <View style={{ width: "100%", height: "25%", backgroundColor: "black" }}>
              {/* <WebView
              ref={(ref) => webViewRef.current = ref}
              allowFileAccess={true}
              // injectedJavaScript={jsCode}
              scalesPageToFit={true}
              originWhitelist={['*']}
              style={{ backgroundColor: "black" }}
              source={{ uri: 'https://diapofamille-b6fc7.web.app?diapo=' + user.uid }}>
            </WebView> */}
              <iframe src={"https://diapofamille-b6fc7.web.app?diapo=" + user.uid + "&code=" + code} style={{ height: "100%", textDecoration: "none" }} frameBorder="0"></iframe>
            </View>

            {/* */}

            <View style={styles.row}>

              {/* <Text style={styles.title}>Welcome {user.email}!</Text>
        <Text>{windowHeight}</Text> */}

            </View>
            {/* <Text style={styles.text}>Your UID is: {user.uid} </Text> */}
            {/* <Button
            title="Photos"
            onPress={() => navigation.navigate('Photos')}
          /> */}

            {/* <Button
            title="QR Code"
            onPress={() => navigation.navigate('QRcode', {
              uuid: user.uid
            })}
          /> */}

            {imagesNumber < imagesLimit &&
              <TouchableHighlight
                style={{ backgroundColor: "#800087", height: windowHeight / 18, justifyContent: "center", alignItems: "center", borderRadius: 40, marginBottom: 20 }}
                onPress={() => navigation.navigate('Upload', {
                  uuid: user.uid,
                  imagesNumber: imagesNumber,
                  imagesLimit: imagesLimit
                })}
                underlayColor='#fff'>
                <Text style={{ color: "white", fontSize: 16, fontWeight: "600" }} >Ajouter des photos</Text>
              </TouchableHighlight>
            }

            {imagesNumber >= imagesLimit &&
              <TouchableHighlight
                style={{ backgroundColor: "white", height: windowHeight / 18, justifyContent: "center", alignItems: "center", borderRadius: 40, marginBottom: 20, borderWidth: 2, borderColor: "red" }}
                onPress={() => setModalVisibleLimitAddImages(!modalVisibleLimitAddImages)}
                underlayColor='#fff'>
                <Text style={{ color: "white", fontSize: 16, fontWeight: "600", color: "red" }} >Ajouter de photo bloqué</Text>
              </TouchableHighlight>
            }

            <TouchableHighlight
              disabled={imagesNumber <= 0 ? true : false}
              style={{ opacity: imagesNumber <= 0 ? 0 : 1, backgroundColor: "#800087", height: windowHeight / 18, justifyContent: "center", alignItems: "center", borderRadius: 40, marginBottom: 20 }}
              onPress={() => navigation.navigate('QRcode', {
                uuid: user.uid,
                code: code
              })}
              underlayColor='#fff'>
              <Text style={{ color: "white", fontSize: 16, fontWeight: "600" }} >Partager le diaporama</Text>
            </TouchableHighlight>



            <TouchableHighlight
              disabled={imagesNumber <= 0 ? true : false}
              style={{ opacity: imagesNumber <= 0 ? 0 : 1, backgroundColor: "#800087", height: windowHeight / 18, justifyContent: "center", alignItems: "center", borderRadius: 40, marginBottom: 40 }}
              onPress={() => navigation.navigate('Photos', {
                uuid: user.uid
              })}
              underlayColor='#fff'>
              <Text style={{ color: "white", fontSize: 16, fontWeight: "600" }} >Liste des photos</Text>
            </TouchableHighlight>


            {/* <Text style={styles.title}>------</Text>
      <Button
        title="Upload"
        onPress={() => navigation.navigate('Upload', {
          uuid: user.uid
        })}
      /> */}


            {/* <Button
        title="Refresh webview"
        onPress={() => reloadWebview()
        }
      /> */}
            <View style={{ position: "absolute", bottom: "0" }}>
              
              <Text style={{ color: "white", marginBottom: 0, fontSize: 16, fontWeight: "600", textAlign: "center" }}>Nombre de photos : {imagesNumber} / {imagesLimit}</Text>
              <Text style={{ color: "white", marginBottom: 20, fontSize: 16, fontWeight: "600", textAlign: "center" }}>Effacement des photos dans {days} jours</Text>

              <TouchableHighlight
                style={{ backgroundColor: "white", width: "100%", height: windowHeight / 18, justifyContent: "center", alignItems: "center", borderRadius: 40 }}
                onPress={() => setModalVisibleLimitAddImages(!modalVisibleLimitAddImages)}
                underlayColor='#fff'>
                <Text style={{ color: "red", fontSize: 16, fontWeight: "600" }} >Passer en PREMIUM</Text>
              </TouchableHighlight>


              <TouchableHighlight
                style={{ backgroundColor: "white", width: "100%", height: windowHeight / 18, justifyContent: "center", alignItems: "center", borderRadius: 40, marginTop: 20 }}
                onPress={() => setModalVisible(true)}
                underlayColor='#fff'>
                <Text style={{ color: "red", fontSize: 16, fontWeight: "600" }} >Supprimer toutes les photos</Text>
              </TouchableHighlight>




              <View style={{ width: "100%", justifyContent: 'center', alignItems: "center", flexDirection: "row", marginTop: 20, marginBottom: 40 }}>
                <AntDesign
                  name='logout'
                  size={24}
                  color='#fff'
                  onPress={handleSignOut}
                />

                <TouchableHighlight
                  style={{ height: 24, justifyContent: "center", alignItems: "center", borderRadius: 40, marginLeft: 10 }}
                  onPress={handleSignOut}
                  underlayColor='#fff'>
                  <Text style={{ color: "white", fontSize: 16, fontWeight: "600" }} >Se déconnecter</Text>
                </TouchableHighlight>

              </View>





            </View>







          </View>





          {modalVisible &&
            <View pointerEvents="none" style={{ position: "absolute", width: "100%", height: "100%", backgroundColor: 'rgba(0,0,0,0.3)' }} />
          }

          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              Alert.alert('Modal has been closed.');
              setModalVisible(!modalVisible);
            }}>
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Text style={styles.modalText}>Voulez-vous supprimer toutes les photos ?</Text>
                <Text style={styles.modalText}>Un nouveau diaporama sera créé et il faudra repartager le nouveau lien !</Text>

                <ActivityIndicator size="small" color="black" style={{
                  marginTop: 50,
                  width: 50,
                  height: 0,
                  justifyContent: "center",
                  display: deleteSpinerVisible ? "block" : "none"
                }} />

                <View style={{ flexDirection: "row", marginTop: 20, marginBottom: 20 }}>

                  <Pressable
                    style={[styles.button, styles.buttonClose, { backgroundColor: "#dc3545", display: deleteSpinerVisible ? "none" : "block" }]}
                    onPress={deleteFolder}>
                    <Text style={styles.textStyle}>Supprimer toutes les photos</Text>
                  </Pressable>
                </View>

                <Pressable
                  style={[styles.button, styles.buttonClose, { marginTop: 20, marginBottom: 0, display: deleteSpinerVisible ? "none" : "block" }]}
                  onPress={() => setModalVisible(!modalVisible)}>
                  <Text style={styles.textStyle}>Annuler</Text>
                </Pressable>
              </View>
            </View>
          </Modal>




          {modalVisibleLimitAddImages &&
            <View pointerEvents="none" style={{ position: "absolute", width: "100%", height: "100%", backgroundColor: 'rgba(0,0,0,0.3)' }} />
          }

          <Modal
            propagateSwipe={true}
            animationType="slide"
            transparent={true}
            visible={modalVisibleLimitAddImages}
            onRequestClose={() => {
              Alert.alert('Modal has been closed.');
              setModalVisible(!modalVisible);
            }}>
            <ScrollView>
              <View style={styles.centeredView}>
                <View style={styles.modalView}>
                  <Text style={[styles.modalText, { fontWeight: "700", marginBottom: 0 }]}>Limite de photo {imagesNumber} / {imagesLimit}   </Text>
                  <Text style={[styles.modalText, { fontWeight: "700" }]}>Limite de stockage des photos : {days} jours   </Text>
                  <Text style={styles.modalText}>Vos photos seront automatiquement éffacées dans {days} jours. </Text>
                  {!transactionComplete &&
                    <Text style={styles.modalText}>Passer en mode Premium pour augmenter le nombre de photo à 100, et passer à 30 jours de stockage, pour 5€. </Text>
                  }
                  {transactionComplete &&
                    <Text style={styles.modalText}>Statut PREMIUM</Text>
                  }

                  <View style={{ flexDirection: "row", marginTop: 20, marginBottom: 20 }}>

                    {/* <Pressable
                  style={[styles.button, styles.buttonClose, { backgroundColor: "#dc3545", display: deleteSpinerVisible ? "none" : "block" }]}
                  onPress={() => saveLimitOfPhotos()}>
                  <Text style={styles.textStyle}>Premium pendant 1 mois / 5€</Text>
                </Pressable> */}
                    {!transactionComplete &&

                      <PayPalButton
                        amount="5"
                        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                        //   createOrder={(data, actions) => {
                        //     return actions.order.create({
                        //         purchase_units: [{
                        //             amount: {
                        //                 currency_code: "USD",
                        //                 value: "0.01"
                        //             }
                        //         }],
                        //         // application_context: {
                        //         //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                        //         // }
                        //     });
                        // }}
                        onSuccess={(details, data) => {
                          //alert("Transaction completed by " + details.payer.name.given_name);
                          saveLimitOfPhotos(true);
                          // OPTIONAL: Call your server to save the transaction
                          console.log("Payement effectué !!!!!!");
                          setTransactionComplete(true);
                          return fetch("/paypal-transaction-complete", {
                            method: "post",
                            body: JSON.stringify({
                              orderID: data.orderID
                            })
                          });
                        }}
                        options={{
                          clientId: "AY6UOEB5ExtOfORkkcgFHF8zLq-4s-aZOUobvfR9MJa_A6SZV0PjtGFgoGcJR1vt6aJZrf4sSjiZNUjs"
                        }}
                      />


                    }
                    {transactionComplete &&

                      <Text style={styles.modalText}>Paiment effectué, vous êtes passé Premium pendant 1 mois. </Text>


                    }
                  </View>

                  <Pressable
                    style={[styles.button, styles.buttonClose, { marginTop: 20, marginBottom: 0, display: deleteSpinerVisible ? "none" : "block" }]}
                    onPress={() => setModalVisibleLimitAddImages(!modalVisibleLimitAddImages)}>
                    <Text style={styles.textStyle}>{transactionComplete ? "Fermer" : "Annuler"}</Text>
                  </Pressable>
                </View>

              </View>
            </ScrollView>
          </Modal>



        </View>

        <AntDesign
          name='youtube'
          size={35}
          color='#fff'
          onPress={() => Linking.openURL("https://www.youtube.com/channel/UCYfguOt7iZEnxSgNUNffNQQ")}
          style={{ position: "absolute", right: 12, top: 15, display: windowWidth <= 400 ? "none" : "block" }}
        />


        <AntDesign
          name='instagram'
          size={35}
          color='#fff'
          onPress={() => Linking.openURL("https://www.instagram.com/diapofamille")}
          style={{ position: "absolute", right: 12, top: 62, display: windowWidth <= 400 ? "none" : "block" }}
        />

        <AntDesign
          name='mail'
          size={35}
          color='#fff'
          onPress={() => Linking.openURL("mailto:contactdiapofamille@gmail.com")}
          style={{ position: "absolute", right: 12, top: 110, display: windowWidth <= 400 ? "none" : "block" }}
        />


      




      </LinearGradient>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    //flex: 1
    height: "100%",
    margin: 0,
    padding: 0
    //backgroundColor: '#e93b81',
    // paddingTop: 50,
    // paddingHorizontal: 12
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    // color: '#fff'
  },
  text: {
    fontSize: 16,
    fontWeight: 'normal',
    color: '#fff'
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    marginLeft: 20,
    marginRight: 20
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    marginLeft: 10,
    marginRight: 10
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});
